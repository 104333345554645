<template>
  <div class="pageContent">
    <searchHeader />
    <div class="productionDetails">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="proInfo">
            <div class="proImage">
              <img :src="orderDetails.img" v-if="orderDetails.img !== '--'" />
            </div>
            <div class="proContent">
              <p class="commodity">{{ orderDetails.commodity }}</p>
              <div class="desc">
                <p>
                  <span class="item">
                    <span class="itemTitle">商品分类：</span> 
                    <span class="itemText">{{ orderDetails.className }}</span>
                  </span>
                </p>
                 <p>
                  <span class="item">
                    <span class="itemTitle">商品编码：</span>
                    <span class="itemText">{{ orderDetails.iconSys ? orderDetails.iconSys : '---' }}</span>
                  </span>
                </p>
                <!-- <p>
                  <span
                    class="item"
                    v-for="(item,index) of orderDetails.specificationsName"
                    :key="index"
                  >
                    <span class="itemTitle">商品属性：</span>
                    <span class="itemText">{{ item.label }}</span>
                  </span>
                </p> -->
                <p>
                  <span class="item">
                    <span class="itemTitle">重量：</span>
                    <span class="itemText">{{ orderDetails.hangWeight }}{{orderDetails.unit}}</span>
                  </span>
                  <span  class="item" v-if="orderDetails.tradeWay=='P'&&orderDetails.minPickWeight">
                    <span class="itemTitle">&nbsp;&nbsp; 最小起订量：</span>
                    <span class="itemText">{{ Number(orderDetails.unsettledWeight)>=Number(orderDetails.minPickWeight)?orderDetails.minPickWeight:orderDetails.unsettledWeight }}{{orderDetails.unit}}</span>
                  </span>
                </p>
                <p v-if="orderDetails.quantity">
                  <span class="item">
                    <span class="itemTitle">数量：</span>
                    <span class="itemText">{{ orderDetails.quantity }}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">订货单价（含税）：</span>
                    <span class="itemText price">{{ orderDetails.price }}元/{{orderDetails.unit}}</span>
                  </span>
                  <span class="item">
                    <span class="itemTitle">合计（含税）：</span>
                    <span class="itemText zjPrice">{{ orderDetails.zjPrice }}元</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">预计入库时间：</span>
                    <span class="itemText">{{ orderDetails.putWarehousTime | DateFormateDay }}</span>
                  </span>
                </p>
              </div>
            </div>
            <div class="proOrderOperate">
              <el-button type="danger" @click="showOrderDialog(orderDetails)">立即购买</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="proDetails">
            <p class="proDetailsTitle">商品详情</p>
            <div class="proDetailsContent">
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>商品分类：{{ orderDetails.className }}</p>
                </el-col>
                 <!-- <el-col :span="6">
                  <p>商品编码：{{ orderDetails.iconSys ? orderDetails.iconSys : '---' }}</p>
                </el-col> -->
                <el-col
                  :span="6"
                  v-for="(item,index) of orderDetails.specificationsName"
                  :key="index"
                >
                  <p>商品属性：{{ item.label }}</p>
                </el-col>
                 <el-col :span="6" v-if="orderDetails.holdManufacturer">
                  <p>生产厂家：{{ orderDetails.holdManufacturer }}</p>
                </el-col>
                 <el-col :span="6" v-if="orderDetails.placeOrigin">
                  <p>产地：{{ orderDetails.placeOrigin }}</p>
                </el-col>
                 <el-col :span="6" v-if="orderDetails.brand">
                  <p>品牌：{{ orderDetails.brand }}</p>
                </el-col>
                <el-col :span="6">
                  <p>重量：{{ orderDetails.hangWeight }}{{ orderDetails.unit }}</p>
                </el-col>
                <el-col :span="6">
                  <p>可摘单重量：{{ orderDetails.unsettledWeight }}{{ orderDetails.unit }}</p>
                </el-col>
                <!-- </el-row>
                <el-row :gutter="20">-->
                <el-col :span="6" v-if="orderDetails.quantity">
                  <p>数量：{{ orderDetails.quantity }}</p>
                </el-col>
                <el-col :span="6">
                  <p>订货单价（含税）：{{ orderDetails.price }}元/{{ orderDetails.unit }}</p>
                </el-col>
                <el-col :span="6">
                  <p>合计（含税）：{{ orderDetails.zjPrice }}元</p>
                </el-col>
               
                <el-col :span="6">
                  <p>开票期限：{{ orderDetails.openTicketTime | DateFormateDay }}</p>
                </el-col>
                <!-- </el-row>
                <el-row :gutter="20">-->
                <!-- <el-col :span="6">
                  <p>磅差：{{ orderDetails.poundsPoor }}{{ orderDetails.unit }}</p>
                </el-col>
                <el-col :span="6">
                  <p>生产日期：{{ orderDetails.outWarehousDay | DateFormateDay }}</p>
                </el-col> -->
                <el-col :span="6">
                  <p>是否接受议价：{{ orderDetails.bargainingStatus | isholdFormate }}</p>
                </el-col>
                <el-col :span="6">
                  <p>交易方式：{{ orderDetails.tradeWay | filterBuyWay }}</p>
                </el-col>
                  <el-col :span="6" v-if="orderDetails.tradeWay=='P'">
                  <p>是否开启集采：{{ orderDetails.phaseType=='T'?'开启':'不开' }}</p>
                </el-col>
                <el-col :span="6" v-if="orderDetails.phaseType=='T'">
                  <p>集采重量：{{ orderDetails.phaseWeight }}{{ orderDetails.unit }}</p>
                </el-col>
                <el-col :span="6" v-if="orderDetails.phaseType=='T'">
                  <p>集采分销结束时间：{{ orderDetails.phaseEndTime|DateFormateDay }}</p>
                </el-col>
                <!-- </el-row>
                <el-row :gutter="20">-->
                <!-- <el-col :span="6" v-if="orderDetails.manufacturer != '--'">
                  <p>生产厂家：{{ orderDetails.manufacturer }}</p>
                </el-col> -->
                <el-col :span="6" v-if="this.sessionInfoGetter.isLogin == 'true'">
                  <p>业务负责人：{{ orderDetails.businessName?orderDetails.businessName:'--' }}</p>
                </el-col>
                <el-col :span="6" v-if="this.sessionInfoGetter.isLogin == 'true'">
                  <p>联系电话：{{ orderDetails.businessPhone ?orderDetails.businessPhone:'--'}}</p>
                </el-col>
                <el-col :span="6">
                  <p>交收仓库：{{ orderDetails.sname ? orderDetails.sname : '--' }}</p>
                </el-col>
                 <el-col :span="8">
                  <p>企业名称：{{orderDetails.hangEnterpriseName?orderDetails.hangEnterpriseName:'--'}}</p>
                </el-col>
                <!-- <el-row> -->
                <el-col :span="24" v-if="priceStep.length!=0">
                  <el-row :gutter="20">
                    <el-col :span="2" style="width:76px;">
                      <p class="title">阶梯价：</p>
                    </el-col>
                    <el-col :span="22">
                      <span class="text" v-for="( item,index) of priceStep" :key="index">
                        {{ item.lowWeight }}< X{{index+1}} ≤ {{ item.highWeight }}{{ orderDetails.unit }} &nbsp;{{ item.price }}元/{{ orderDetails.unit }}&nbsp;
                        <i v-if="index<priceStep.length-1" class="setStyle">;</i>&nbsp;&nbsp;
                      </span>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- </el-row> -->
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 下单弹窗 -->
    <orderDialog ref="orderDialog" />
  </div>
</template>
<script>
import searchHeader from "../base/header/searchHeader";
import orderDialog from "./orderDialog";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      proCount: 1,
      priceStep: [],
      orderDetails: {}
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    searchHeader,
    orderDialog
  },
  mounted() {
    this.getOrderDetails();
  },
  methods: {
    getOrderDetails() {
      const that = this;
      protocolFwd.param_queryResourceDetails.param.hangResourcesId = this.$route.params.id;
      protocolFwd.param_queryResourceDetails.param.pickResourcesId = this.$route.params.pickResourcesId;
      http.postFront(protocolFwd.param_queryResourceDetails).then(response => {
        let that = this;
        const { code, message, value } = response.data;
        if (code == 0) {
          value.specificationsName = this.attributeTrans(
            value.specificationsName
          );
          value.zjPrice = util.floatMul(value.price, value.hangWeight);
          this.orderDetails = value;
          if (value.tradeWay == "P") {
            that.getStepPrice(value.hangResourcesId);
          }
          for (let i in this.orderDetails) {
            if (!this.orderDetails[i] && this.orderDetails[i] != 0) {
              this.orderDetails[i] = "--";
            }
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getStepPrice(id) {
      protocolFwd.param_queryPhaseHangPrice.param.hangResourceId = this.$route.params.id;
      http.postFront(protocolFwd.param_queryPhaseHangPrice).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.priceStep = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showOrderDialog(row) {
      if (this.sessionInfoGetter.isLogin == "true") {
        if (this.sessionInfoGetter.firmId == row.hangFirmId) {
          this.$EL_MESSAGE("不允许自买自卖");
          return;
        }
        this.$refs.orderDialog.showDialog(row);
      } else {
        this.$EL_MESSAGE("请先登录");
        this.$router.push("/login");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.productionDetails {
  // width: 80%;
  // min-width: 1300px;
  width: 1200px;
  margin: 10px auto;
}
.productionDetails .proInfo,
.productionDetails .proDetails {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.proInfo {
  position: relative;
  overflow: hidden;
}
.proInfo .proImage {
  float: left;
  width: 350px;
  height: 260px;
}
.proInfo .proImage img {
  max-width: 100%;
  max-height: 100%;
}
.proInfo .proImage p {
  line-height: 260px;
  text-align: center;
  color: #ffffff;
  font-size: 26px;
}
.proInfo .proContent {
  line-height: 30px;
}
.proInfo .proContent .commodity {
  font-size: 16px;
  font-weight: 700;
}
.proInfo .proContent .desc * {
  color: #666666;
}
.proInfo .proContent .desc .item {
  margin-right: 20px;
}
.proInfo .proContent .desc .item .price,
.proInfo .proContent .desc .item .zjPrice {
  font-weight: 700;
  color: $commonThemeColor;
}
.proInfo .proOrderOperate {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.proDetails {
  margin-top: 20px;
}
.proDetails .proDetailsTitle {
  margin-bottom: 10px;
  font-size: 16px;
  color: $commonThemeColor;
}
.proDetails .proDetailsContent {
  line-height: 35px;
  color: #666666;
}
.setStyle {
  font-style: normal;
}
</style>

